<template>
  <main class="overview">
    <h2 v-if="lastupload != '' ? true : false">Hochgeladen!</h2>
    <img
      v-if="lastupload != '' ? true : false"
      :src="MediaURL + lastupload"
      draggable="false"
    />
    <p>{{ takenimages }}/{{ maximages }} Bilder Hochgeladen</p>
    <button
      @click="$router.push({ name: 'camera' })"
      class="bigbutton orange inlinebtn"
      v-if="takenimages < maximages && $store.state.location !== ''"
    >
      Ein weiteres Foto aufnehmen
    </button>
    <button @click="allimages" class="bigbutton orange inlinebtn">
      Meine Bilder zeigen
    </button>
    <a target="_blank" href="https://zugepflastert.de">zugepflastert.de</a>
  </main>
</template>
<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { MediaURL } from "../api";
import { useRouter } from "vue-router";

export default {
  name: "overview",
  setup() {
    const store = useStore();
    const router = useRouter();
    function allimages() {
      router.push({ name: "allimages" });
      store.commit("resetlastupload")
    }

    return {
      lastupload: computed(() => store.state.lastupload),
      maximages: computed(() => store.state.maximages),
      takenimages: computed(() => store.state.takenimages),
      MediaURL,
      allimages,
    };
  },
};
</script>